@font-face {
  font-family: "Noto Sans";
  src: url("../fonts/NotoSans-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

/* Arabic */
@font-face {
  font-family: "Noto Sans Arabic";
  src: url("../fonts/NotoSansArabic-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

/* Hebrew */
@font-face {
  font-family: "Noto Sans Hebrew";
  src: url("../fonts/NotoSansHebrew-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

/* Bengali */
@font-face {
  font-family: "Noto Sans Bengali";
  src: url("../fonts/NotoSansBengali-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

/* Japanese */
@font-face {
  font-family: "Noto Sans JP";
  src: url("../fonts/NotoSansJP-Medium.otf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

/* Korean */
@font-face {
  font-family: "Noto Sans KR";
  src: url("../fonts/NotoSansKR-Medium.otf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

/* Chinese - simplified and traditional */
@font-face {
  font-family: "Noto Sans SC";
  src: url("../fonts/NotoSansSC-Medium.otf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans TC";
  src: url("../fonts/NotoSansTC-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

/* Armenian, Ethiopic, Gujarati, Kannada, Khmer, Lao, Malayalam, Gurmukhi, Sinhala, Thai, Tamil, Telugu, Tibetan */
@font-face {
  font-family: "Noto Sans Armenian";
  src: url("../fonts/NotoSansArmenian-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Ethiopic"; /* Amharic */
  src: url("../fonts/NotoSansEthiopic-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Georgian";
  src: url("../fonts/NotoSansGeorgian-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Gujarati";
  src: url("../fonts/NotoSansGujarati-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Kannada";
  src: url("../fonts/NotoSansKannada-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Khmer";
  src: url("../fonts/NotoSansKhmer-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Lao";
  src: url("../fonts/NotoSansLao-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Malayalam";
  src: url("../fonts/NotoSansMalayalam-VariableFont_wdth,wght.ttf")
    format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Gurmukhi"; /* Punjabi */
  src: url("../fonts/NotoSansGurmukhi-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Sinhala";
  src: url("../fonts/NotoSansSinhala-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Tamil";
  src: url("../fonts/NotoSansTamil-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Telugu";
  src: url("../fonts/NotoSansTelugu-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Thai";
  src: url("../fonts/NotoSansThai-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Serif Tibetan";
  src: url("../fonts/NotoSerifTibetan-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}
